import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { FileType } from '../../data/File.types';
import { ModelFile } from '../../data/ModelFile';
import { FileBase } from '../../data/FileBase';
import { CommandError } from '../../ErrorStore';
import { SpaceContext } from '../../SpaceContext';
import { SpaceFile } from '../../data/SpaceFile';
import { ModelContext } from '../../ModelContext';
import { SchemeName, SetSchemeCommand } from '../apps/SetSchemeCommand';

interface LoadFileCommandParams extends IParams {
  fileId: string;
  fileType: FileType;
  fileExtension?: string;
  version?: string;
}

interface FileLoadedEventParams extends IParams {
  file: FileBase;
  fileType: FileType;
  fileExtension?: string;
  version?: string;
}

export class FileLoadedEvent extends EventBase {
  static eventType = 'FileLoadedEvent';

  constructor(
    public readonly params: FileLoadedEventParams,
    public readonly source = LoadFileCommand,
  ) {
    super();
  }
}

export class LoadFileCommand extends CommandBase<LoadFileCommandParams> {
  execute(params: LoadFileCommandParams): FileLoadedEvent | CommandError {
    // console.log('LoadFileCommand: execute', params);
    let file: FileBase;
    if (params.fileType === FileType.Model) {
      if (!params.fileExtension) {
        return CommandError.of(new Error('File extension is required for model files'), 'error');
      }
      file = this.appState.fileStoreClient.loadFile<ModelFile>({
        fileId: params.fileId,
        version: params.version,
        type: FileType.Model,
        onSynced: () => {
          setTimeout(() => {
            // if (this.appState.store.getState().isLoaded) return;
            // this.appState.store.getState().setIsLoaded(true);
            // const scripts: ScriptBase[] = [];
            // const changedEntities: EntityBase[] = [];
            // this.getModelContext()
            //   .entityRepository.list()
            //   .forEach((entity) => {
            //     // if (entity instanceof ScriptBase) {
            //     //   const script = entity as ScriptBase;
            //     //   const isDevMode = this.appState.store.getState().devMode;
            //     //   script.modeHeight = script.calculatedHeight(isDevMode);
            //     //   scripts.push(script);
            //     //   const children = this.getModelContext().entityRepository.getAllUniqueChildren([script.id]);
            //     //   children.delete(script);
            //     //
            //     //   children.forEach((child) => {
            //     //     if (script === child) return;
            //     //     child.modeIsVisible = script.isEntityFilteredForDevMode(child, isDevMode) ? 'true' : 'false';
            //     //     changedEntities.push(child);
            //     //     script.repositionChild(child, isDevMode);
            //     //   });
            //     //   changedEntities.push(script);
            //     // }
            //   });
            //
            // const changes = changedEntities.map((e) => ({ entity: e, modifiedProperties: [] }));
            // const changeSet: EntityChanges = { added: [], updated: changes, deleted: [] };
            // this.appState.observer.notify(NotificationTypes.OnTransientChange, changeSet);
          }, 0);
        },
      });
      this.setActiveScheme(params.fileExtension);
      ModelContext.setActiveModel(file as ModelFile);
    } else {
      file = this.appState.fileStoreClient.loadFile<FileBase>({
        fileId: params.fileId,
        version: params.version,
        type: params.fileType,
      });
      if (params.fileType === FileType.Space) {
        SpaceContext.getInstance().setActiveSpace(file as SpaceFile);
        this.appState.fileTree.clear();
      }
    }
    return new FileLoadedEvent({
      file: file,
      fileType: params.fileType,
      fileExtension: params?.fileExtension,
      version: params.version,
    });
  }

  private setActiveScheme(fileType: string) {
    //const currentScheme = this.adaptor.getSetting(`${spaceId}_scheme`);
    if (fileType === 'em') {
      this.appState.messageBus.sendInternal(SetSchemeCommand, {
        scheme: SchemeName.EventModelling,
        spaceId: 'event-modelling',
        save: false,
      });
      return;
    }
    if (fileType === 'ndd') {
      this.appState.messageBus.sendInternal(SetSchemeCommand, {
        scheme: SchemeName.Narrative,
        spaceId: 'narrative',
        save: false,
      });
      return;
    }
    if (fileType === 'ndem') {
      this.appState.messageBus.sendInternal(SetSchemeCommand, {
        scheme: SchemeName.NarrativeAndEventModelling,
        spaceId: 'narrative-and-event-modelling',
        save: false,
      });
      return;
    }
  }
}
